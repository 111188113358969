.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.radio-b-new {
  color: #662549 !important;
}

.radio-b-new-del {
  color: #f39f5a !important;
}

.p-checkbox .p-checkbox-box {
  background: #033c97;
}

.p-radiobutton .p-radiobutton-box {
  background: #e8bcb9;
}

.btn-back {
  background: #662549;
  padding: 0.3rem;
  color: white;
}

.btn-save {
  background: #ae445a;
  padding: 0.3rem;
  color: white;
}

/*?-------------------------------? Customer List -------------------------------- */

.btn-question {
  border: 2px solid #1d1a39;
  /* Çerçeve rengi ve kalınlığı */
  border-radius: 48%;
  /* Çerçeve köşe yarıçapı */
  padding: 5px 10px;
}

.btn-excel {
  background-color: #451952;
  color: white;
  padding: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.btn-excel-two {
  background-color: #662549;
  color: white;
  padding: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.btn-plus {
  background-color: #f39f5a;
  color: white;
  padding: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.btn-plus:hover {
  background-color: #662549;
}

.header-div {
  align-items: center;
  height: 2rem;
}

.p-tieredmenu {
  padding: 0 !important;
}

.p-tieredmenu .p-menuitem-link {
  padding: 0px !important;
}

.p-tieredmenu .p-menuitem-link:hover {
  background: transparent !important;
}

.p-tieredmenu.p-tieredmenu-overlay {
  width: 10%;
  border-radius: 13px 13px 13px 13px;
}

.p-inputtext-lg {
  font-size: 1rem !important;
  padding: 0.54rem 0.54rem !important;
  border: 0.5px solid gray;
}

.p-button-text {
  color: rgb(0, 149, 255) !important;
}

.new-cust {
  background-color: red;
  color: white;
  padding: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.jc-space-around {
  display: flex;
  justify-content: space-around !important;
}

/*? header */

.p-tieredmenu .p-menuitem-link {
  justify-content: center;
}

/*  ? sidebar   */

button a {
  width: 100%;
  border-radius: 13px 13px 13px 13px;
}

button a:hover {
  background: #662549;
  color: white;
  transition: all 0.5s;
}

button a.active {
  background: #662549 !important;
  color: white !important;
}

.p-selectbutton .p-button.p-highlight:hover {
  background: #ae445a;
  border-color: #ae445a;
  color: #ffffff;
}

.p-selectbutton .p-button.p-highlight {
  background: #ae445a;
  border-color: #ae445a;
  color: #ffffff;
}

.p-button.p-button-outlined {
  background-color: transparent;
  border: 1px solid;
}

/* ? dropdowns */
.p-dropdown-panel {
  overflow: hidden;
  max-width: 20rem;
}

.p-inputtext {
  color: #4b5563;
}

.p-dropdown {
  background: #fffdfd;
  border: 1px solid #344c71;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s,
    box-shadow 0.2s;
  border-radius: 6px;
}

.p-breadcrumb {
  overflow-x: visible;
  background: none;
  padding: 0rem;
}

textarea {
  font-size: 1rem !important;
  border: 0.5px solid gray;
}

.p-fileupload .p-fileupload-buttonbar .p-button {
  background-color: #662549;
}

/*  offer*/

tr .p-inputtext {
  color: #1e232b;
  border: 0.1px solid gray;
  padding-left: 0.6rem;
  width: 90%;
}

.p-dropdown .p-inputtext {
  border: none;
}

.p-component-custom {
  border: 1px solid #344c71;
  border-radius: 6px;
}

.p-datatable td {
  font-size: smaller;
  font-weight: 600;
  text-transform: uppercase !important;
}

.p-datatable .p-column-resizer {
  background: rgb(232, 230, 230);
  width: 0.2rem;
  background: #152b37;
}

.p-overlaypanel:before {
  border-color: transparent;
}

.p-card .p-card-body {
  padding: 0rem;
}

/* job details  */
.detail-btn-two {
  background-color: #451952 !important;
  padding: 0.5rem;
}

.detail-btn-two:hover {
  background-color: #692f7b !important;
  transition: background-color 0.5s;
}

.detail-btn-one {
  background-color: #1d1a39 !important;
  padding: 0.5rem;
}

.detail-btn-one:hover {
  background-color: #312d61 !important;
  transition: background-color 0.5s;
}

.detail-btn-three {
  background-color: #f39f5a !important;
  padding: 0.5rem;
}

.detail-btn-three:hover {
  background-color: #ffb06f !important;
  transition: background-color 0.5s;
}

.p-timeline .p-timeline-event-marker {
  border: 1px solid #f39f5a !important;
}

.p-timeline-event-content {
  flex: 0;
}

.p-timeline-left .p-timeline-event-opposite {
  width: 20rem;
}

.p-button.p-button-icon-only {
  width: 1.6rem;
}

/* sms */

li .p-menuitem-link:hover {
  background-color: #1d1a39;
  color: white;
  transition: all 0.8s;
}

label {
  font-family: sans-serif;
}


.carousel-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  margin: 0 auto;
}

.carousel-container button {
  padding: 8px 12px;
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
}

.carousel-container img {
  width: 100%;
  max-width: 500px;
  height: auto;
  object-fit: cover;
}

.carousel-nav {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.carousel-nav span {
  font-size: 16px;
  color: black;
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.5);

}

.carousel-nav span:hover {
  background-color: rgba(0, 0, 0, 0.3);
}

@media print {
  body {
    padding: 0;
    size: A4;
    font-size: 10px;
    margin: 5mm;
  }

  .printCss {
    width: 90%
  }

  .css-jv498s {
    background: rgb(102, 37, 73);
  }

}


.confirm-popup-reject {
  margin-right: 1rem;
}

.p-confirm-popup:before {
  border-color: none;
}

@media (min-width: 992px) {
  .packages-col {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 20%;
  }
}